.projects__container {
    grid-template-columns: repeat(2, 370px);
    justify-content: center;
    column-gap: 1.8rem;
}

.projects__content {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 4rem 0 2rem 2.5rem;
}

.projects__icon {
    display: block;
    font-size: 3.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}

.projects__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}

.projects__button {
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.projects__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.projects__button:hover .projects__button-icon {
    transform: translateX(00.25rem);
}

.projects__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition-timing-function: ease-in-out;
    transition: 0.5s ;
}

.projects__modal-content {
    width: 800px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.project__modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.project__modal-title,
.project__modal-description {
    text-align: center;
}

.project__modal-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.project__modal-description {
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.project__modal-projects {
    row-gap: 0.75rem;
}

.project__modal-project {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.project__modal-icon {
    color: var(--title-color);
    font-size: 1.1rem;
}

.project__modal-info {
    font-size: var(--small-font-size);
}


.active-modal {
    opacity: 1;
    visibility: visible;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .projects__container {
        grid-template-columns: repeat(2, 350px);
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .projects__container {
        grid-template-columns: repeat(1, 350px);
    }

    .projects__content {
        padding: 3.5rem 0 .5rem 1.25rem 1.5rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .projects__modal-content {
        padding: 4.5rem 1.5rem 2.5rem;
    }

    .project__modal-description {
        padding: 0;
    }    
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .projects__container {
        grid-template-columns: max-content;
    }

    .projects__content {
        padding-right: 6rem;
    }
  }
  

.imageselect .imagebox {
    position: relative;
}

.imageselect .imagebox figure {
    position: relative;
    overflow: hidden
}

.imageselect .imagebox figcaption {
    background: var(--container-color);
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
    padding: 20px;
    -webkit-transform: translateY(140%);
    -moz-transform: translateY(140%);
    -ms-transform: translateY(140%);
    -o-transform: translateY(140%);
    transform: translateY(140%);
    -moz-transition: -moz-transform 0.5s ease;
    -o-transition: -o-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease
}

.imageselect .imagebox .caption-title {
    margin-bottom: 0;
    font-size: 1.1rem;
    color: var(--title-color);
    justify-content: center;
}

.imageselect .imagebox .price {
    color: var(--title-color)
}

.imageselect .imagebox figcaption {
    border-radius: 1.5rem;
    font-size: 1.1rem;
}

.imageselect .imagebox:hover figcaption {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
}

.image {
    width: 100%;
    border-radius: 1.5rem;
}

.price {
    color: var(--title-color);
    font-size: 1.1rem;
    text-transform: uppercase;
    float: right;
    text-align: right;
    line-height: 1;
    display: block
}

.img1 {
    background: url(../../assets//p2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
    justify-self: center;
    width: 100%;
    height: 23rem;
}
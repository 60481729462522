/* this is the style for the container inside the app.js */
/* you can remove it and just use the card CSS */

  /* ======================================================== */
  .card {
    transform-style: preserve-3d;
    position: relative;
    padding: 25px;
    width: 350px;
    height: 500px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background: linear-gradient(
      25deg,
      rgb(255, 255, 255) 0%,
      rgba(0, 139, 139, 1) 86%
    );
    transition: all 0.1s ease;
  }
  .paint-img {
    position: relative;
    width: 60%;
    transition: all 0.5s ease;
  }
  .title {
    width: 100%;
    color: var(--title-color);
  }
  .card p {
    width: 100%;
    color: var(--body-font);
  }
  .sizes-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
  }
  .sizes-box li {
    list-style: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
    background-color: var(--body-color);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .sizes-box li:hover {
    color: var(--newcolor);
  }
  .button-box {
    width: 100%;
  }
  .purchase {
    cursor: pointer;
    width: 100%;
    border-radius: 30px;
    padding: 0.8rem 1.5rem;
    font-weight: bold;
    border: none;
    font-size: 1rem;
    letter-spacing: 1px;
    background-color: var(--newcolor);
    color: var(--body-color);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: .5s ease-in-out;
  }
  .purchase:hover {
    background-color: rgba(0, 0, 0, 0.0);
    scale: 1.1;
    color: var(--newcolor);
  }